<template>
  <div class="">
    <ZeroDepot :souscription="souscription"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <p class="form-titre-page result">
              J&#x27;ai trouvé mon futur logement et je souhaite remplacer mon dépôt de garantie de <span class="text-span-10">{{loyer}}€</span> par l&#x27;assurance Zéro Dépôt pour <span class="text-span-9">{{$store.api.price(montant)}}€/mois.</span>
            </p>
            <a class="bouton-continuer w-button" @click="$parent.next()">CONTINUER</a>
            <div class="div-zone-info">
              <p class="text"><span class="text-span-11">Vous n&#x27;avez pas encore de bail ?</span> Vous pouvez demander une attestation provisoire, <a @click="changeType()" href="javascript:void(0)" class="link">cliquez ici</a>.</p>
              <p class="text"><span class="text-span-11">Vous souhaitez en savoir plus sur l&#x27;assurance Zéro Dépôt ?</span> <a href="https://pautions.fr/" target="_blank" class="link">Cliquez ici</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "FStep3",
  props: ['souscription', 'user', 'proprietaire'],
  components: {
    ZeroDepot
  },
  data(){
    return {
      ready:false,
      montant: null
    }
  },
  computed:{
    loyer(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee == true){
        tmp = tmp*2;
      }
      return tmp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  },
  methods: {
    changeType(){
      this.souscription.last_step = 2;
      this.souscription.provisoir = true;
    }
  },
  mounted() {
    var params = {
      loyer: this.souscription.loyer,
      meublee: this.souscription.meublee
    };
    this.$store.api.ajax('/zero-depot/prix', params, (res) => {
      if(res.status === true){
        this.montant = res.data;
        this.ready = true;
      }
    })
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
