<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div v-if="ready">
      <div id="ZONE-INFO-ATTESTATION" class="zone-info-attestation wf-section">
        <div class="content-info-attestation w-container">
          <div class="w-layout-grid grid-info-attestation">
            <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a832e-be6cbc8a" class="img-info"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="image-8"></div>
            <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a8330-be6cbc8a" class="txt-info-attestation">
              <p class="paragraphe-txt-info-attestation">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
              <p class="paragraphe-txt-info-attestation light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="zone-content-form wf-section">
        <div class="container-content-form-suite w-container">
          <div class="w-layout-grid grid-form">
            <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
            <div class="div-block-10">
              <div class="form-step-2-souscription w-form">
                <form>
                  <div class="div-type-location">
                    <p class="form-titre-page step2">Vous recherchez un logement en location</p>
                    <select v-model="souscription.meublee" required class="select-type-location w-select">
                      <option :value="false">vide</option>
                      <option :value="true">meublée</option>
                    </select>
                  </div>
                  <div class="div-montant-loyer">
                    <p class="form-titre-page">Pour un loyer estimé de</p>
                    <input type="number" min="1" max="3000" v-model="souscription.loyer" class="form_calculateur v2 texte-field w-input" maxlength="256"  placeholder="1 000" required="">
                  </div>
                  <div v-if="souscription.loyer > 3000">
                    <p class="info_loyer_eligible">Le montant que vous avez renseigné <span class="text-span-29">n'est pas éligible</span> et dépasse la prise en charge de l'assurance Zéro Dépôt.</p>
                  </div>
                  <a v-if="checkForm" class="bouton-continuer w-button" @click="$parent.next()">CONTINUER</a>
                  <a v-else class="bouton-continuer w-button btn-no-valide">CONTINUER</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "AStep2",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire', 'params'],
  data(){
    return {
      ready:false
    }
  },
  computed: {
    checkForm(){
      var l = this.souscription.loyer;
      return l < 3001 && l != null;
    }    
  },
  mounted() {
    //do something after mounting vue instance
    this.ready = true
  }
};
</script>
<style>
select{
  appearance: none;
}
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
