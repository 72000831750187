<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <p class="form-titre-page step08">Souscription à l&#x27;assurance Zéro Dépôt<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-step9">Veuillez indiquer le type de paiement souhaité :<span class="text-span-9"></span></p>
            <div class="div-zone-paiements">
              <div class="div-paiement-annuel" :class="{'select': souscription.paiement.periode === 'mensuel'}">
                  <p class="paiement" :class="{'select': souscription.paiement.periode === 'mensuel'}">Paiement mensuel</p>
                  <p class="prix" :class="{'select': souscription.paiement.periode === 'mensuel'}">{{$store.api.price(souscription.prix_mensuel)}}€/mois</p>
                  <p class="prix-sous-titre" :class="{'select': souscription.paiement.periode === 'mensuel'}" style="visibility: hidden;">au lieu de {{$store.api.price(souscription.prix_mensuel*12)}}€/an</p>
                  <p class="_1ere-ligne-prix-violet annuel" :class="{'select': souscription.paiement.periode === 'mensuel'}">1er paiement</p>
                  <p class="_2eme-ligne-prix-violet" :class="{'blanc': souscription.paiement.periode === 'mensuel'}">de {{$store.api.price(souscription.prix_mensuel + souscription.frais_adhesion)}}€</p>
                  <p class="description-prix" :class="{'select': souscription.paiement.periode === 'mensuel'}">Ce paiement inclus votre première mensualité ainsi que {{$store.api.price(souscription.frais_adhesion)}}€ de frais d&#x27;adhésion.</p>
                  <a @click="souscription.paiement.periode = 'mensuel'" class="bouton-select-prix w-button">sélectionner</a>
              </div>
              <div class="div-paiement-annuel" :class="{'select': souscription.paiement.periode === 'annuel'}">
                  <p class="paiement" :class="{'select': souscription.paiement.periode === 'annuel'}">Paiement annuel</p>
                  <p class="prix" :class="{'select': souscription.paiement.periode === 'annuel'}">{{$store.api.price(souscription.prix_annuel)}}€/an</p>
                  <p class="prix-sous-titre" :class="{'select': souscription.paiement.periode === 'annuel'}">au lieu de {{$store.api.price(souscription.prix_annuel + souscription.prix_mensuel)}}€/an</p>
                  <p class="_1ere-ligne-prix-violet annuel" :class="{'select': souscription.paiement.periode === 'annuel'}">1er paiement</p>
                  <p class="_2eme-ligne-prix-violet" :class="{'blanc': souscription.paiement.periode === 'annuel'}">de {{$store.api.price(souscription.prix_annuel + souscription.frais_adhesion)}}€</p>
                  <p class="description-prix" :class="{'select': souscription.paiement.periode === 'annuel'}">Ce paiement inclus votre première mensualité ainsi que {{$store.api.price(souscription.frais_adhesion)}}€ de frais d&#x27;adhésion.</p>
                  <a @click="souscription.paiement.periode = 'annuel'" class="bouton-select-prix w-button">sélectionner</a>
              </div>
            </div>
            <div v-if="souscription.paiement.periode != null">
              <p class="form-3nd-titre-step9">Quel mode de paiement souhaitez-vous utiliser :<span class="text-span-9"></span></p>
              <div class="div-mode-paiement">
                <a @click="souscription.paiement.type_id = 1" class="bouton-rib w-button" :class="souscription.paiement.type_id === 1 ? 'bouton-paiement-type-rib' : ''">RIB Bancaire</a>
                <a @click="souscription.paiement.type_id = 2" class="bouton-cb w-button" :class="souscription.paiement.type_id === 2 ? 'bouton-paiement-type-cb' : ''">Carte de crédit / débit</a>
              </div>
              <div class="form-step-9-souscription w-form">
                <form class="form-2" ref="form_souscription">
                  <div v-if="souscription.paiement.type_id === 1">
                    <div class="div-champs-form nomrib">
                      <p class="texte-info-form step9">Titulaire du compte</p>
                      <input type="text" v-model="rib.titulaire" class="champs-formulaire nom-carte w-input" placeholder="Nom et prénom" required/>
                    </div>
                    <div class="div-champs-form bic-rib">
                      <p class="texte-info-form step9">IBAN</p>
                      <input type="text" v-model="rib.iban" maxlength="33" class="champs-formulaire nom-carte w-input" placeholder="Indiquez votre IBAN" required/>
                    </div>
                  </div>
                  <div v-if="souscription.paiement.type_id === 2">
                    <div class="div-champs-form">
                      <p class="texte-info-form step9">N° de votre carte</p>
                      <div class="div-block-12">
                        <input v-model="cb.nb" type="text" class="champs-formulaire numero-cb w-input" maxlength="19" placeholder="0000 0000 0000 0000" required/>
                        <img v-if="checkCB() != false" width="40" loading="lazy" :src="require('@/assets/images/'+checkCB())" alt="" class="image-6" />
                      </div>
                    </div>
                    <div class="div-champs-form nomcb">
                      <p class="texte-info-form step9">Nom sur votre carte</p>
                      <input v-model="cb.name" type="text" class="champs-formulaire nom-carte w-input" maxlength="256" placeholder="Nom" required/>
                    </div>
                    <div class="div-champs-form dateexp">
                      <p class="texte-info-form step9 dateexp">Date d&#x27;expiration</p>
                      <div class="div-block-13">
                        <input v-model="cb.date" type="tel" class="champs-formulaire date-expiration-cb w-input" maxlength="7" name="Date-expiration-CB" data-name="Date expiration CB" placeholder="MM/YYYY" id="Date-expiration-CB" required/>
                        <p class="texte-info-form step9 cvv">CVV</p>
                        <input v-model="cb.cvv" type="tel" class="champs-formulaire cvv w-input" maxlength="4" name="CVV" data-name="CVV" placeholder="000" required/>
                      </div>
                    </div>
                  </div>
                  <label class="w-checkbox checkbox-accepter">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2" :class="cgv ? 'w--redirected-checked' : ''" @click="cgv = cgv === false ? true : false"></div>
                    <span class="checkbox-label-2 w-form-label" for="checkbox-paiement">
                      J&#x27;accepte la <a href="https://pautions.fr/charte-protection-donnees-personnelles.html" target="_blank" class="link-text-info"><span class="text-span-13">charte de protection des données personnelles</span></a> et les
                      <a href="https://pautions.fr/conditions-utilisation.html" target="_blank" class="link-text-info"><span class="text-span-14">conditions générales d&#x27;utilisation</span></a>.
                    </span>
                  </label>
                  <a style="text-align: center;" v-if="getPrice() != null && spinner === false" class="bouton-continuer mail w-button" @click="update()">
                    <span>Payer {{getPrice()}}€</span>
                  </a>
                  <div v-else style="text-align: center;">
                    <img :src="require('@/assets/images/spinner.svg')">
                    <p style="color:rgb(251, 177, 205);font-weight:bold;font-size:18px;">Merci de patienter pendant la génération de votre contrat.</p>
                  </div>
                  <p class="info_paiment">Votre premier paiement sera prélevé à la date de prise d'effet de votre bail.</p>
                </form>
              </div>
              <div class="div-secu-paiement">
                <img :src="require('@/assets/images/ic_lock.svg')" loading="lazy" width="15" alt="" />
                <p class="info-paiement-securite">Votre paiement est sécurisé et crypté par<span class="text-span-9"></span></p>
                <img :src="require('@/assets/images/ic_stripe.svg')" loading="lazy" width="30" alt="" class="image-7" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "FStep9",
  components: {
    ZeroDepotLogin
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      cgv:false,
      spinner:false,
      cb:{
        type_id:2,
        nb:null,
        name:null,
        date:null,
        cvv:null
      },
      rib:{
        type_id:1,
        iban: null,
        titulaire: null,
        bic: null
      }
    }
  },
  computed: {
  },
  watch:{
    'cb.nb'(v){
      if(v === null){
        return false
      }
      this.cb.nb = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'rib.iban'(v){
      if(v === null){
        return false
      }
      this.rib.iban = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'cb.date'(v){
      if(v === null){
        return false;
      }
      if(v.length === 2){
        this.cb.date +="/";
      }
    }
  },
  methods: {
    update(){
      if(this.checkForm() === false){
        this.$toastr.e("Merci de renseigner tous les champs.");
        return false
      }
      if(this.spinner === true){
        return false;
      }
      var params = {
        user: this.user,
        souscription: this.souscription,
        paiement: this.souscription.paiement.type_id === 1 ? this.rib : this.cb
      };
      this.spinner = true;
      this.$store.api.ajax('/paiement/make', params, (res) => {
        if(res.status === true){
          location.href = res.data.url_locataire
        }else{
          this.spinner = false;
        }
      });
    },
    checkForm(){
      this.user;
      this.souscription;
      this.rib;
      this.cb;
      var res = true;
      if(this.$refs.form_souscription.reportValidity() === false){
        res = false;
      }
      if(this.cgv === false){
        res = false;
      }
      if(this.souscription.paiement.type_id === null){
        res = false;
      }
      return res;
    },
    checkCB(){
      if(this.cb.nb === null){
        return false;
      }
      var cc = this.cb.nb;
      cc = cc.replaceAll(" ","");
      var res = false;
      var amex = new RegExp('^3[47][0-9]+$');
      var visa = new RegExp('^4[0-9]+$');
      var mastercard = new RegExp('^5[1-5][0-9]+$');
      var mastercard2 = new RegExp('^2[2-7][0-9]+$');
      if (visa.test(cc)) {
        res = 'ic-visa.svg';
      }
      if (amex.test(cc)) {
        res = 'ic_amex.svg';
      }
      if (mastercard.test(cc) || mastercard2.test(cc)) {
        res = 'ic_ mastercard.svg';
      }
      return res;
    },
    getPrice(){
      var prix = null;
      if(this.souscription.paiement.periode === null){
        return prix;
      }
      prix = this.souscription.paiement.periode === 'annuel' ? this.souscription.prix_annuel : this.souscription.prix_mensuel;
      prix = prix + this.souscription.frais_adhesion;
      return this.$store.api.price(prix)
    },
  },
  mounted() {
    this.ready = true
  }
};
</script>
<style>
.bouton-paiement-type-rib{
  background-color: #242089;
  background-image: url(/img/ic_rib_blanc.56d983c4.svg);
  color: #fff;
}
.bouton-paiement-type-cb{
  background-color: #242089;
  background-image: url(/img/ic_cb_blanc.09fc00ab.svg);
  color: #fff;
}
.prix-mensuel-select{
  color: #fff;
  font-weight: 700;
}
.bg-grey{
  background-color: grey;
}
.bg-grey:active{
  background-color: grey;
}
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
  .info_paiment {
    width: 100% !important;
  }
}
@media only screen and (min-width: 991px) {
  .div-paiement-annuel:first-child{
    margin-left: 0px !important;
  }
}
.select .bouton-select-prix.w-button{
  background: #868686;
}
.select .bouton-select-prix.w-button:hover{
  background: #868686;
}
</style>
