<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <div class="div-coup-dur-titre">
              <p class="form-titre-page coup-dur">Souhaitez-vous ajouter l&#x27;option &quot;Coup dur&quot; ?<span class="text-span-9"></span></p>
              <p class="coup-dur-prix">+{{getCoupDur()}}€/mois</p>
            </div>
            <div class="form-step-5-souscription w-form">
              <form class="form-2">
                <div class="div-champs-form">
                  <p class="texte-info-form violet">
                    Pour bénéficier de la garantie &quot;Coup dur&quot;, vous devez être salarié et disposer d&#x27;un CDI de plus de 6 mois, <span class="text-span-16">ou</span> être indépendant et exercer votre activité depuis
                    plus d&#x27;1 an.
                  </p>
                </div>
                <div class="checkbox-oui-non" v-if="souscription.coup_dur === null">
                  <label class="w-checkbox checkbox-field" @click="souscription.coup_dur = true">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-oui"></div>
                    <input type="checkbox" name="" id="" data-name="" required="" style="opacity: 0; position: absolute; z-index: -1;" /><span class="w-form-label"></span>
                  </label>
                  <label class="w-checkbox checkbox-field-2" @click="souscription.coup_dur = false">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-non"></div>
                    <input type="checkbox" name="" id="" data-name="" required="" style="opacity: 0; position: absolute; z-index: -1;" /><span class="w-form-label"></span>
                  </label>
                </div>
                <div class="checkbox-oui-non" v-else>
                  <label class="w-checkbox checkbox-field" @click="souscription.coup_dur = true">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-oui" :class="souscription.coup_dur ? 'w--redirected-checked' : ''"></div>
                    <input type="checkbox" name="" id="" data-name="" required="" style="opacity: 0; position: absolute; z-index: -1;" /><span class="w-form-label"></span>
                  </label>
                  <label class="w-checkbox checkbox-field-2" @click="souscription.coup_dur = false">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-non" :class="souscription.coup_dur === false ? 'w--redirected-checked' : ''"></div>
                    <input type="checkbox" name="" id="" data-name="" required="" style="opacity: 0; position: absolute; z-index: -1;" /><span class="w-form-label"></span>
                  </label>
                </div>
                <div class="div-zone-info coup-dur">
                  <p class="text rose"><span class="text-span-11">Qu&#x27;est-ce que la garantie &quot;Coup dur&quot; ?</span></p>
                  <p class="paragraph-14">La garantie &quot;Coup dur&quot; prend en charge <span class="text-span-15">1 mois de loyer</span> dans les cas suivants :</p>
                  <ul role="list">
                    <li class="liste-point">Perte d&#x27;emploi ou cessation d&#x27;activité.</li>
                    <li class="liste-point">Incapacité totale de travail (ITT).</li>
                    <li class="liste-point">Perte totale et irréversible d&#x27;autonomie (PTIA).</li>
                    <li class="liste-point">Décès accidentel de l&#x27;assuré.</li>
                  </ul>
                </div>
                <a v-if="form_valide" style="text-align: center;" class="bouton-continuer w-button" @click="update()">CONTINUER</a>
                <a v-else style="text-align: center;" class="bouton-continuer w-button btn-no-valide">CONTINUER</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "FStep7",
  components: {
    ZeroDepotLogin
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false
    }
  },
  computed: {
    form_valide(){
      return this.souscription.coup_dur != null
    }
  },
  methods: {
    update(){
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/souscription', params, (res) => {
        if(res.status === true){
          this.souscription.prix_annuel = res.data.prix_annuel
          this.souscription.prix_mensuel = res.data.prix_mensuel
          this.$parent.next();
        }
      })
    },
    getCoupDur(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee === true){
        tmp = tmp*2;
      }
      tmp = (tmp*5.67)/100
      tmp = tmp/12;
      tmp = tmp*1.15;
      return Math.ceil(tmp);
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
