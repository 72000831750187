<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user" />
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"> <a @click="$parent.previous()" class="zone-precedent w-inline-block"></a> </div>
          <form class="form-2" ref="form_logement">
            <div class="div-content-form">
              <p class="form-titre-page">Indiquez les informations de votre futur logement<span
                  class="text-span-9"></span></p>
              <div class="form-step-5-souscription w-form">

                <div class="div-champs-form adresse">
                  <p class="texte-info-form">Adresse du futur logement</p>
                  <vue-google-autocomplete id="map" classname="champs-formulaire adresse w-input" placeholder="Adresse"
                    v-on:placechanged="getAddressData" country="fr"> </vue-google-autocomplete>
                </div>
                <div class="div-champs-form code-postal">
                  <p class="texte-info-form">Code postal et ville</p>
                  <input type="text" v-model="souscription.logement.cp" class="champs-formulaire code-postal w-input"
                    placeholder="00000" readonly required="" />
                  <input type="text" v-model="souscription.logement.ville" class="champs-formulaire ville w-input"
                    placeholder="Ville" readonly required="" />
                </div>
                <div class="div-champs-form adresse-plus">
                  <p class="texte-info-form">Complément d&#x27;adresse</p>
                  <input type="text" v-model="souscription.logement.adresse2"
                    class="champs-formulaire adresse-plus w-input" placeholder="Ex : Bâtiment, Résidence, etc..." />
                </div>
                <div class="div-champs-form date-bail">
                  <p class="texte-info-form">Date de prise d&#x27;effet du bail</p>
                  <input type="date" :min="min_date" v-model="souscription.logement.date_bail"
                    class="champs-formulaire datebail w-input" placeholder="Jour/Mois/Année" required="" />
                </div>
              </div>
            </div>
            <div class="div-content-form" style="margin-top:70px">
              <p class="form-titre-page" style="font-size:23px">Propriétaire / Gestionnaire :<span
                  class="text-span-9"></span></p>
              <div class="form-step-5-souscription w-form">
                <div class="div-champs-form nomproprio" style="margin-top:0px">
                  <p class="texte-info-form nom-proprio">Nom et prénom</p>
                  <input type="text" v-model="proprietaire.nom" class="champs-formulaire nom-proprio w-input"
                    maxlength="256" placeholder="Nom" required />
                  <input type="text" v-model="proprietaire.prenom" class="champs-formulaire nom-proprio w-input"
                    maxlength="256" placeholder="Prénom" required />
                </div>
                <div class="div-champs-form mailproprio">
                  <p class="texte-info-form">Mail du propriétaire</p>
                  <input type="email" v-model="proprietaire.email" class="champs-formulaire adresse w-input"
                    placeholder="mail@mail.com" required="" />
                </div>
                <div class="div-champs-form tel-proprio">
                  <p class="texte-info-form">Téléphone du propriétaire</p>
                  <input type="text" v-model="proprietaire.telephone" minlength="10" maxlength="10"
                    class="champs-formulaire adresse w-input" placeholder="0102030405" required="" />
                </div>
                <a style="text-align: center;" class="bouton-continuer w-button" @click="update()">CONTINUER</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  name: "FStep6",
  components: {
    ZeroDepotLogin, VueGoogleAutocomplete
  },
  props: ['souscription', 'user', 'proprietaire'],
  watch: {
    'proprietaire.telephone'() {
      this.proprietaire.telephone = this.proprietaire.telephone.replaceAll(" ", "")
    }
  },
  data() {
    return {
      ready: false,
      form_valide: false,
      adresse_auto: false
    }
  },
  computed: {
    date_naissance() {
      return this.user.date_naissance.split("-").reverse().join('/')
    },
    min_date() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return `${year}-${month}-${day}`
    }
  },
  methods: {
    update() {
      if (this.checkForm() === false) {
        return false;
      }
      var params = {
        user: this.user,
        souscription: this.souscription,
        proprietaire: this.proprietaire
      };
      this.$store.api.ajax('/zero-depot/souscription', params, (res) => {
        if (res.status === true) {
          this.$gtag.event('finalisation_souscription', { method: 'finalisation_souscription' });
          this.$parent.next();
        }
      })
    },
    checkForm() {
      var res = true;
      if (this.$refs.form_logement.reportValidity() === false) {
        res = false;
      }
      return res;
    },
    getAddressData(q) {
      var d = document.getElementById("map");
      var adresse = '';
      adresse += q.street_number != undefined ? q.street_number : '';
      adresse += q.route != undefined ? ' ' + q.route : '';
      if (adresse != '') {
        this.souscription.logement.adresse = adresse;
        d.value = adresse;
      }
      if (q.locality != undefined) {
        this.souscription.logement.ville = q.locality;
      }
      if (q.postal_code != undefined) {
        this.souscription.logement.cp = q.postal_code;
      }
      this.souscription.logement.lon = q.longitude;
      this.souscription.logement.lat = q.latitude;
      this.adresse_auto = true;
    }
  },
  mounted() {
    this.ready = true;
    setTimeout(() => {
      if(this.souscription.logement.adresse != null){
        var d = document.getElementById("map");
        d.value = this.souscription.logement.adresse;
      }
    },500);
  }
};
</script>
<style>
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}

.champs-formulaire.nom-proprio {
  max-width: 235px;
  margin-left: 30px;
}

@media screen and (max-width: 991px) {
  .champs-formulaire.nom-proprio {
    max-width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .champs-formulaire.nom-proprio {
    max-width: 330px;
    margin-top: -5px;
    margin-left: 0px;
  }
}
</style>
