<template>
  <div v-if="ready">
    <ZeroDepot :souscription="souscription"/>
    <div id="ZONE-INFO-ATTESTATION" class="zone-info-attestation wf-section">
      <div class="content-info-attestation w-container">
        <div class="w-layout-grid grid-info-attestation">
          <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a832e-be6cbc8a" class="img-info"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="image-8"></div>
          <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a8330-be6cbc8a" class="txt-info-attestation">
            <p class="paragraphe-txt-info-attestation">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
            <p class="paragraphe-txt-info-attestation light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zone-content-form wf-section">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <p class="form-titre-page">Indiquez vos coordonnées<span class="text-span-9"></span></p>
            <div class="form-step-5-souscription w-form">
              <form class="form-2" ref="form_info">
                <div class="div-champs-form">
                  <p class="texte-info-form mrmme">Je suis</p>
                  <input type="text" v-model="user.nom" class="champs-formulaire nom w-input" maxlength="256" placeholder="Nom" required />
                  <input type="text" v-model="user.prenom" class="champs-formulaire pr-nom w-input" maxlength="256" placeholder="Prénom" required/>
                </div>
                <div class="div-champs-form date">
                  <p class="texte-info-form datenaissance">Je suis né(e) le</p>
                  <input type="date" :max="getDate18" v-model="user.date_naissance" class="champs-formulaire date w-input" maxlength="256" placeholder="Jour/Mois/Année" required/>
                </div>
                <div class="div-champs-form tel">
                  <p class="texte-info-form tel">Mon téléphone</p>
                  <input type="text"  placeholder="0102030405" minlength="10" maxlength="10" v-model="user.telephone" class="champs-formulaire tel w-input" name="Telephone" data-name="Telephone" id="Telephone" required="" />
                </div>
                <div class="div-champs-form tel">
                  <p class="texte-info-form tel">Mon mail</p>
                  <input type="email" class="champs-formulaire tel w-input" v-model="user.email" placeholder="mon.mail@gmail.com" required/>
                </div>
                <label class="w-checkbox checkbox-accepter"  @click="optinn = optinn === false ? true : false;">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2" :class="optinn ? 'w--redirected-checked' : ''"></div>
                  <span class="checkbox-label-2 w-form-label" for="checkbox">
                    J&#x27;accepte la <a href="https://pautions.fr/charte-protection-donnees-personnelles.html" target="_blank" class="text-span-13">charte de protection des données personnelles</a>, les <a href="https://pautions.fr/conditions-utilisation.html" target="_blank" class="text-span-13">conditions générales d&#x27;utilisation</a> et que les données collectées ci-dessous soient
                    utilisées pour me recontacter afin de finaliser ma souscription si nécessaire.
                  </span>
                </label>
                <a class="bouton-continuer w-button" @click="create()" style="text-align:center">Valider mes coordonnées</a>
              </form>
              <div class="w-form-fail" v-if="error != false">
                <div>{{error}}</div>
              </div>
            </div>
            <div class="div-block-9" v-if="send">
              <p class="texte-info"><span class="text-span-12">Nous venons de vous envoyer un mail à l&#x27;adresse que vous avez indiqué.</span></p>
              <p class="texte-info">Merci de valider votre inscription à votre compte Pautions et poursuivre la souscription à l&#x27;Assurance Zéro Dépôt.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "AStep4",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  watch:{
    'user.telephone'(){
      this.user.telephone = this.user.telephone.replaceAll(" ","")
    }
  },
  data(){
    return {
      ready:false,
      optinn:false,
      error:false,
      send:false
    }
  },
  computed: {
    getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day
      return `${year}-${month}-${day}`
    },
  },
  methods: {
    checkForm(){
      this.user === this.user
      var res = true;
      if(this.$refs.form_info.reportValidity() === false){
        res = false;
      }
      if(this.optinn === false){
        res = false;
      }
      return res;
    },
    create(){
      if(this.checkForm() === false){
        return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/attestation', params, (res) => {
        if(res.status === true){
          this.souscription.id = res.data.id;
          this.souscription.user_id = res.data.user_id;
          this.user.id = this.souscription.user_id;
          this.$parent.next();
        }
      });      
    }
  },
  mounted() {
    this.ready = true
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
