<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <p class="form-titre-page step08">Valider les informations relatives à votre contrat<span class="text-span-9"></span></p>
            <div class="div-resume-info">
              <div class="w-layout-grid grid-resume-info-1er-partie">
                <div id="w-node-_52bc2d9f-6a86-dba1-9c1a-b76b5ee6023e-10d71e8f" class="premiere-partie">
                  <p class="titre-resume-info">Votre Assurance Zéro Dépôt :</p>
                  <div class="div-info-perso">
                    <p class="texte-info-form violet">
                      Vous êtes : <span class="bold-info-perso">{{user.prenom}} {{user.nom}}</span><br />
                      Né(e) le : <span class="bold-info-perso">{{date_naissance}}</span><br />
                      Mail : <span class="bold-info-perso">{{user.email}}</span><br />
                      Téléphone : <span class="bold-info-perso">{{user.telephone}}</span>
                    </p>
                  </div>
                </div>
                <div id="w-node-_698d5c82-0fbb-3a20-cd63-a69f394bc652-10d71e8f" class="documents-infos">
                  <p class="titre-doc-infos">Documents d&#x27;informations :</p>
                  <a :href="$store.api.urlAPI+'/docs/CG.pdf'" target="_blank" class="texte-doc-infos">Conditions générales </a>
                  <a :href="$store.api.urlAPI+'/docs/idip.pdf'" target="_blank" class="texte-doc-infos">Notice d&#x27;information et garanties</a>
                  <a :href="$store.api.urlAPI+'/docs/PJ.pdf'" target="_blank" class="texte-doc-infos">Protection juridique</a>
                </div>
              </div>
              <div class="div-info-logement">
                <p class="texte-info-form violet"><span class="text-span-17">Votre futur logement :</span><span class="bold-info-perso"></span></p>
                <p class="texte-info-form violet">
                  Adresse : <span class="bold-info-perso">{{souscription.logement.adresse}}</span><br />
                  Ville : <span class="bold-info-perso">{{souscription.logement.ville}}</span><br />
                  Code postal : <span class="bold-info-perso">{{souscription.logement.cp}}</span><br />
                  Propriétaire : <span class="bold-info-perso">{{proprietaire.prenom}} {{proprietaire.nom}}<br /></span>Son mail : <span class="bold-info-perso">{{proprietaire.email}}<br /></span>Son n° de téléphone :
                  <span class="bold-info-perso">{{proprietaire.telephone}}<br /></span>
                </p>
                <a @click="$parent.previous(7)" class="bouton-modifier w-button">Modifier</a>
              </div>
              <div class="div-info-coup-dur">
                <p class="texte-info-form violet coupdur"><span class="text-span-18">Garantie &quot;Coup dur&quot; :</span><span class="bold-info-perso"></span></p>
                <p class="coup-dur-info">{{souscription.coup_dur === true ? 'oui' : 'non'}}</p>
              </div>
              <a class="bouton-continuer valider-info w-button" @click="update()" style="display: inline-block;">VALIDER MES INFORMATIONS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "FStep8",
  components: {
    ZeroDepotLogin
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false
    }
  },
  computed: {
    form_valide(){
      return this.souscription.coup_dur != null
    },
    date_naissance(){
      return this.user.date_naissance.split("-").reverse().join('/')
    }
  },
  methods: {
    update(){
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/souscription', params, (res) => {
        if(res.status === true){
          this.$parent.next();
        }
      })
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
