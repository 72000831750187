<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user"/>
    <div class="zone-content-form wf-section" v-if="ready == true">
      <div class="container-content-form-suite w-container" v-if="spinner === false && error === false">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page merci">Félicitations !<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-merci">La signature et le paiement de votre contrat ont bien été pris en compte. Nous vous remercions de votre confiance.<span class="text-span-9"></span></p>
            <p class="texte-merci">Nous venons d'envoyer un email au propriétaire de votre logement afin qu'il puisse à son tour effectuer la signature de votre contrat en ligne. Pour rappel, la signature de ce contrat ne lui coûtera rien.</p>
            <p class="texte-merci _2">Une fois que le propriétaire aura signé, vous recevrez un email avec votre contrat validé ainsi que les documents d'informations et de garanties en pièces jointes.</p>
            <a class="bouton-espace-pautions" href="/#/" style="text-decoration: initial;">MON ESPACE PAUTIONS</a>
          </div>
        </div>
      </div>
      <div class="container-content-form-suite w-container" v-else>
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page merci">Oups !<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-merci">Il semblerait qu'il y ait un problème technique.<span class="text-span-9"></span></p>
            <p class="texte-merci">Merci de nous contacter par mail, <a href="mailto:contact@pautions.fr">contact@pautions.fr</a>.</p>            
          </div>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center;">
      <img :src="require('@/assets/images/spinner.svg')">
      <p style="color:rgb(251, 177, 205);font-weight:bold;font-size:18px;">Merci de patienter pendant la génération de votre contrat.</p>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "FStep10",
  components: {
    ZeroDepotLogin
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      spinner:false,
      error:false
    }
  },
  computed: {
  },
  watch:{
  },
  methods: {
  },
  beforeMount() {
    //do something before mounting vue instance
    // if(this.souscription.last_step === 10 && this.souscription.docusign.locataire === false){
    //   location.href = this.souscription.docusign.url_locataire
    // }
  },
  mounted() {
    if(this.$route.query.event != undefined ){
      if(this.$route.query.event === "signing_complete" || this.$route.query.event === "viewing_complete"){
        this.ready = true;
        this.$store.api.ajax('/zero-depot/locataire/'+this.souscription.uuid, null, res => {
          res.t = "55";
          this.ready = true;
          this.spinner = false;
          this.$gtag.event('en_attente_signature_proprio', { method: 'en_attente_signature_proprio' });
        })
      }
    }else if (this.souscription.docusign.locataire === false) {
      this.$store.api.ajax('/sign/locataire/link', {uuid:this.souscription.uuid}, res => {      
        if(res.status === true){
          location.href = res.data;
        }else{
          this.ready = true;
          this.spinner = false;
          this.error = true;
        }
      })
    }else{
      this.ready = true;
      this.spinner = false;
    }
  }
};
</script>
<style>
.bouton-paiement-type-rib{
  background-color: #242089;
  background-image: url(/img/ic_rib_blanc.56d983c4.svg);
  color: #fff;
}
.bouton-paiement-type-cb{
  background-color: #242089;
  background-image: url(/img/ic_cb_blanc.09fc00ab.svg);
  color: #fff;
}
.prix-mensuel-select{
  color: #fff;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
