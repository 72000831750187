<template>
  <div>
    <ZeroDepotLogin :souscription="souscription" :user="user"/>
    <div class="zone-content-form wf-section">
      <div class="content-azd-info w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a href="/#/accueil" class="zone-precedent w-inline-block"></a></div>
          <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-dc20802d" class="div-content-form">
            <p class="titre-info-azd">Vous allez finaliser votre souscription à l&#x27;assurance Zéro Dépôt, bravo !<span class="text-span-9"></span></p>
            <div class="div-info-azd">
              <p class="texte-info"><span class="text-span-12">Avant de continuer, nous vous rappelons quelques points :</span></p>
              <ul role="list">
                <li class="list-item-azd-info">
                  <p class="txt-info-azd">Pour pouvoir souscrire à l&#x27;assurance Zéro Dépôt, il vous faut l&#x27;accord préalable de votre futur propriétaire / gestionnaire. Il sera invité lui aussi à signer en ligne votre contrat.</p>
                </li>
                <li class="list-item-azd-info">
                  <p class="txt-info-azd">L&#x27;assurance Zéro Dépôt supprime le versement du dépôt de garantie (tant mieux pour vous !). En remplacement, votre propriétaire / gestionnaire sera couvert et indemnisé d&#x27;un mois de loyer en cas de dégradation immobilière lors de votre départ du logement.<br/></p>
                </li>
              </ul>
              <a @click="$parent.next();" class="bouton-continuer w-button">C&#x27;est parti !</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "FStep6",
  components: {
    ZeroDepotLogin,
  },
  props: ['souscription', 'user', 'proprietaire'],
  watch:{
    'proprietaire.telephone'(){
      this.proprietaire.telephone = this.proprietaire.telephone.replaceAll(" ","")
    }
  },
  data(){
    return {
      ready:false,
      form_valide: false,
      adresse_auto:false
    }
  },
  computed: {
  },
  methods: {    
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style>
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
