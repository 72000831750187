<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div class="zone-content-form wf-section">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page merci">Bravo !<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-merci">Votre demande a bien été prise en compte.<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-merci vert">Vous allez recevoir un mail de Pautions <span class="text-span-22">afin de confirmer la création de votre compte et par la suite télécharger votre attestation provisoire.</span><span class="text-span-9"></span></p>
            <p class="texte-merci">Notre équipe reste à votre disposition si vous rencontrez un problème pour récupérer votre attestation provisoire. Vous pouvez nous contacter par téléphone au 09 70 38 68 26 ou par mail à support.client@pautions.fr.</p>
            <a class="bouton-end-souscri w-button" style="text-align:center" @click="email()">cliquez-ici pour le renvoyer</a>
            <p class="txt-link-site-pautions"><a href="https://pautions.fr/" class="link-text-info">Retournez sur le site pautions.fr</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "AStep6",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false
    }
  },
  computed: {
  },
  methods: {
    email(){
      this.$store.api.ajax('/zero-depot/email/'+this.souscription.uuid, null , res => {
        if(res.status === true){
          this.$toastr.s(res.data);
        }
      })
    }
  },
  beforeMount() {
    //do something before mounting vue instance
  },
  mounted() {
    if(this.$route.query.final === 'true'){
      this.$parent.flag = false;
      this.$parent.next();
    }else {
      this.ready = true;
      this.$gtag.event('en_attente_inscription', { method: 'en_attente_inscription' });
    }
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
