<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-content-form">
            <p class="form-titre-page">Renseigner vos coordonnées pour poursuivre votre inscription<span class="text-span-9"></span></p>
            <div class="form-step-5-souscription w-form">
              <form class="form-2" ref="form_user">
                <div class="div-champs-form">
                  <p class="texte-info-form mrmme">Je suis</p>
                  <input type="text" v-model="user.nom" class="champs-formulaire nom w-input" maxlength="256" placeholder="Nom" required />
                  <input type="text" v-model="user.prenom" class="champs-formulaire pr-nom w-input" maxlength="256" placeholder="Prénom" required/>
                </div>
                <div class="div-champs-form date">
                  <p class="texte-info-form datenaissance">Je suis né(e) le</p>
                  <input type="date" :max="getDate18" v-model="user.date_naissance" class="champs-formulaire date w-input" maxlength="256" placeholder="Jour/Mois/Année" required/>
                </div>
                <div class="div-champs-form tel">
                  <p class="texte-info-form tel">Mon téléphone</p>
                  <input type="text"  placeholder="0102030405" minlength="10" maxlength="10" v-model="user.telephone" class="champs-formulaire tel w-input" name="Telephone" data-name="Telephone" id="Telephone" required="" />
                </div>
                <div class="div-champs-form tel">
                  <p class="texte-info-form tel">Mon mail</p>
                  <input type="email" class="champs-formulaire tel w-input" v-model="user.email" placeholder="mon.mail@gmail.com" required/>
                </div>
                <label class="w-checkbox checkbox-accepter">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2" :class="optinn ? 'w--redirected-checked' : ''" @click="optinn = optinn === false ? true : false;"></div>
                  <span class="checkbox-label-2 w-form-label" for="checkbox">
                    J&#x27;accepte la <a href="https://pautions.fr/charte-protection-donnees-personnelles.html" target="_blank" class="text-span-13">charte de protection des données personnelles</a>, les <a href="https://pautions.fr/conditions-utilisation.html" target="_blank" class="text-span-13">conditions générales d&#x27;utilisation</a> et que les données collectées ci-dessous soient
                    utilisées pour me recontacter afin de finaliser ma souscription si nécessaire.
                  </span>
                </label>
                <a class="bouton-continuer w-button" @click="emailCheck()">Valider mes coordonnées</a>
              </form>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "FStep4",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  watch:{
    'user.telephone'(){
      this.user.telephone = this.user.telephone.replaceAll(" ","")
    }
  },
  data(){
    return {
      ready:false,
      form_valide:false,      
      uuid:false,
      error:false,
      load:false,
      optinn:false
    }
  },
  computed: {
    getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day;
      return `${year}-${month}-${day}`
    },
  },
  methods: {
    emailCheck(){
      if(this.$refs.form_user.reportValidity() === false){
        return false;
      }
      if(this.load === true || this.optinn === false){
        return false
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/souscription', params, (res) => {
        if(res.status === true){
          this.souscription.uuid = res.data.uuid;
          this.souscription.last_step = res.data.last_step;
          this.souscription.id = res.data.id;
          this.souscription.user_id = res.data.user_id;
          this.user.id = res.data.user_id;
          this.$parent.next();          
        }else{
          this.error = res.data
        }
      });
    },    
  },
  mounted() {
    this.ready = true
  }
};
</script>
<style media="screen">
.w-disabled {
  background-color: #9e9e9e !important
}
.w-form-fail {
  display: block !important
}
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
