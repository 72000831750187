
<template>
  <div>
    <ZeroDepotLogin :user="user" :souscription="souscription"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
          <div class="div-block-10">
            <div class="form-step-2-souscription w-form">
              <form>
                <div class="div-type-location">
                  <p class="form-titre-page step2">Le logement que vous avez trouvé est une location</p>
                  <select v-model="souscription.meublee" required class="select-type-location w-select">
                    <option :value="false">vide</option>
                    <option :value="true">meublée</option>
                  </select>
                </div>
                <div class="div-montant-loyer">
                  <p class="form-titre-page">Pour un loyer de</p>
                  <input type="number" min="1" max="3000" v-model="souscription.loyer" class="form_calculateur v2 texte-field w-input" maxlength="256"  placeholder="1 000" required="">
                  <p class="form-titre-page info">(charges comprises)</p>
                </div>
                <a v-if="checkForm" class="bouton-continuer w-button" @click="$parent.next()">CONTINUER</a>
                <a v-else class="bouton-continuer w-button btn-no-valide">CONTINUER</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZeroDepotLogin from '@/components/Menu/ZeroDepotLogin';
export default {
  name: "AStep7",
  components: {
    ZeroDepotLogin
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false
    }
  },
  computed: {
    checkForm(){
      var l = this.souscription.loyer;
      return l < 3001 && l != null;
    }
  },
  mounted() {
    //do something after mounting vue instance
    this.souscription.loyer = null;
    this.ready = true
  }
};
</script>
<style>
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
select{
  appearance: none;
}
</style>
