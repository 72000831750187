<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div v-if="ready">
      <div id="ZONE-INFO-ATTESTATION" class="zone-info-attestation wf-section">
        <div class="content-info-attestation w-container">
          <div class="w-layout-grid grid-info-attestation">
            <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a832e-be6cbc8a" class="img-info"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="image-8"></div>
            <div id="w-node-d8ec4795-799f-d4d1-fa82-2641a14a8330-be6cbc8a" class="txt-info-attestation">
              <p class="paragraphe-txt-info-attestation">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
              <p class="paragraphe-txt-info-attestation light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="zone-content-form wf-section">
        <div class="container-content-form-suite w-container">
          <div class="w-layout-grid grid-form">
            <div class="div-precedent"><a @click="$parent.previous()" class="zone-precedent w-inline-block"></a></div>
            <div class="div-content-form">
              <p class="form-titre-page result">
                Je suis en recherche d&#x27;un logement. Au lieu de dépenser
                <span class="text-span-20">{{loyer}}€</span>
                pour un dépôt de garantie, je pourrais souscrire à l'assurance Zéro Dépôt pour
                <span class="text-span-20">{{$store.api.price(montant)}}€/mois</span>
                une fois mon logement trouvé.
              </p>
              <a class="bouton-continuer w-button" @click="$parent.next()">Continuer</a>
              <div class="div-zone-info">
                <p class="text">
                  <span class="text-span-11">Vous êtes déjà en possession de votre bail ?</span>
                  Vous pouvez souscrire directement,
                   <a href="javascript:void(0)" @click="changeType()" id="Link-attestation" class="link">cliquez ici</a>.</p>
                   <p class="text"><span class="text-span-11">Vous souhaitez en savoir plus sur l&#x27;Assurance Zéro Dépôt ? </span>
                     <a href="https://pautions.fr/" id="Link-attestation" target="_blank" class="link">Cliquez ici</a>.
                   </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "AStep3",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire', 'params'],
  data(){
    return {
      ready:false,
      montant: null
    }
  },
  methods: {
    changeType(){
      this.souscription.provisoir = false;
      this.souscription.last_step = 2;
      this.souscription.loyer = null;
    }
  },
  mounted() {
    var params = {
      loyer: this.souscription.loyer,
      meublee: this.souscription.meublee
    };
    this.$store.api.ajax('/zero-depot/prix', params, (res) => {
      if(res.status === true){
        this.montant = res.data;
        this.ready = true;
      }
    })
  },
  computed:{
    loyer(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee == true){
        tmp = tmp*2;
      }
      return tmp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
