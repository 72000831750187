<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div class="zone-content-form start wf-section" v-if="ready">
      <div class="container-content-form-start w-container">
        <p class="form-titre-page">Avez-vous le bail ou projet de bail de votre futur logement ?</p>
        <div class="form-step-1-souscription w-form">
            <div>
              <label class="w-checkbox reponse-bail oui" @click="souscription.provisoir = false">
                <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox" :class="souscription.provisoir === false ? 'w--redirected-checked' : ''"></div>
                <span class="checkbox-label w-form-label" for="Bail-oui"><span class="text-span-7">Oui</span>, j&#x27;ai trouvé mon futur logement</span>
              </label>
              <label class="w-checkbox reponse-bail non" @click="souscription.provisoir = true">
                <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox" :class="souscription.provisoir === true ? 'w--redirected-checked' : ''"></div>
                <span class="checkbox-label w-form-label" for="Bail-non"><span class="text-span-8">Non</span>, je suis en recherche de logement</span>
              </label>
            </div>
            <a v-if="checkForm" class="bouton-continuer w-button" @click="$parent.next()">CONTINUER</a>
            <a v-else class="bouton-continuer w-button btn-no-valide">CONTINUER</a>          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
    }
  },
  watch:{
  },
  computed: {
    checkForm(){
      return this.souscription.provisoir != null;
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>
