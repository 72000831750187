<template>
  <div>
    <ZeroDepot :souscription="souscription"/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent" ></div>
          <div class="div-content-form">
            <p class="form-titre-page">Connectez-vous à votre espace Pautions<span class="text-span-9"></span></p>
            <div class="form-inscription-locataire w-form">              
              <p class="txt-inscription-locataire">
                <span class="text-span-30" style="color:#151515">Vérifier mes coordonnées :</span><br>
                Vous êtes : {{user.prenom}} {{user.nom}}<br>
                Né(e)  le : {{formatDate(user.date_naissance)}}<br>
                Mail : {{user.email}}<br>
                Téléphone : {{user.telephone}}
              </p>              
              <a @click="$parent.previous(4)" style="margin-top:10px" class="bouton-modifier w-button">CORRIGER MES COORDONNÉES</a>
              <form ref="form_mdp" class="form-attestation-complete" aria-label="Mail">
                <p class="form-second-titre">Merci de créer un mot de passe pour votre compte Pautions :<span class="text-span-9"></span></p>
                <div class="div-champs-form pass">
                  <p class="texte-info-form motdepasse">Mot de passe</p>
                  <div class="div-block-11">
                    <input v-model="user.password" type="text" class="champs-formulaire pass w-input" maxlength="256" name="Mot-de-passe" data-name="Mot de passe" placeholder="" id="Mot-de-passe" required="">
                    <img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-visible"><img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-non-visible">
                  </div>
                </div>
                <a style="text-align:center" class="bouton-continuer w-button" @click="update()">
                  Se connecter
                </a>                
              </form>
            </div>
            <div class="div-zone-info">
              <p class="text rose"><span class="text-span-11">C&#x27;est quoi l&#x27;espace Pautions ?</span></p>
              <p class="paragraph-14">
                L&#x27;espace Pautions vous permet de souscrire à l&#x27;assurance Zéro Dépôt. Il vous permet aussi de gérer votre contrat (informations, support client, déclaration de sinistre, etc...) et de bénéficier
                d&#x27;offres ou de promotions exclusives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "FStep5",
  props: ['souscription', 'user', 'proprietaire'],
  components: {
    ZeroDepot
  },
  watch:{
    'user.telephone'(){
      this.user.telephone = this.user.telephone.replaceAll(" ","")
    }
  },
  data(){
    return {
      ready:false,
      loader:false,     
    }
  },
  computed: {
  },
  methods: {
    checkForm(){
      var res = true;
      if(this.$refs.form_mdp.reportValidity() === false){
        res = false;
      }
      return res;
    },
    formatDate(date){
      return date.split('-').reverse().join('/');
    },
    update(){
      if(this.checkForm() === false){
        return false;
      }
      if(this.loader === true){
        return false;
      }
      this.loader = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };      
      this.$store.api.ajax('/zero-depot/souscription', params, (res) => {
        if(res.status === true){
          this.$store.api.ajax('/authorization/create', this.user, (res) => {            
            if(res.status === true){
              this.$gtag.event('attestation_active', { method: 'attestation_active' });
              this.$store.api.saveToken(res.data.token);
              this.$store.api.saveUser(res.data);
              this.$router.push({path:"/accueil"});

            }
          });
        }
      })
    }
  },
  mounted() {
    this.ready = true;
    this.user.password = null;
  }
};
</script>
<style media="screen">
@media only screen and (max-width: 991px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
